export const environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyA0ljciOikRyhZ0M1cc2Nm_xlwrcJmZfZs",
      authDomain: "cix-employer.firebaseapp.com",
      projectId: "cix-employer",
      storageBucket: "cix-employer.appspot.com",
      messagingSenderId: "810718764814",
      appId: "1:810718764814:web:801388235d4dd1b33c415f",
      measurementId: "G-NYJMMQ8J8B"
    },
    apiEnvi: 'qa',
    brokerApiUrl: 'https://cixbrokeraffiliatemanageusers-qa.azurewebsites.net/api',
    cixClientBaseUrl: 'https://cixapigateway-qa.azurewebsites.net',
  };
  